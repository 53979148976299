'use client'
import { useState, useEffect, useCallback, useRef, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import type { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import FocusTrap from 'focus-trap-react'
import type { Options as FocusTrapOptions } from 'focus-trap'
import clsx from 'clsx'

import Backdrop from '@components/backdrop'
import type { Menu as IMenu, MenuItemData } from '@lib/types'
import { Button, Divider, Icon, Link } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'

const focusTrapOptions: FocusTrapOptions = {
  checkCanFocusTrap: (trapContainers: Element[]): any => {
    const results = trapContainers.map((trapContainer: Element) => {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (getComputedStyle(trapContainer).visibility !== 'hidden') {
            resolve()
            clearInterval(interval)
          }
        }, 3)
      })
    })
    // Return a promise that resolves when all the trap containers are able to receive focus
    return Promise.all(results)
  },
  fallbackFocus: 'body',
}

const Container = ({ children }: ComponentPropsWithoutRef<'div'>) => (
  <div className="flex flex-col flex-grow w-full pb-8 overflow-x-hidden overflow-y-auto bg-white sm:w-96">
    {children}
  </div>
)

interface SectionProps extends ComponentPropsWithoutRef<'div'> {
  level2?: MenuItemData
  noDivider?: boolean
}

const Section = ({ children, level2, noDivider = false, ...props }: SectionProps) => {
  return (
    <div {...props}>
      {!noDivider && <Divider className="mx-5" />}
      {children}
    </div>
  )
}

interface LinkPrimaryProps extends MenuItemData, ComponentPropsWithoutRef<any> {
  expandable: boolean
  active?: boolean
}

const LinkPrimary = ({
  icon,
  expandable,
  name,
  route,
  active,
  menuSuperheader,
  isAppInternal,
  isInternal,
  className,

  ...props
}: LinkPrimaryProps) => {
  const Label = (
    <span className="inline-flex items-center text-base font-bold transition-colors pr-50 text-primary hover:text-primary-800">
      {icon !== undefined && <Icon icon={icon} className="h-4 pr-1.5" />}
      {name}
    </span>
  )
  const linkClasses =
    'inline-flex py-2 outline-offset-2 focus:outline-2 focus-visible:outline outline-primary w-full rounded items-center place-content-between'
  return (
    <li
      className={clsx(
        'hover:bg-gray-50 px-5 transition-colors flex place-content-between',
        active && 'bg-gray-50',
        className
      )}>
      <div className="w-full">
        {expandable && (
          <button className={linkClasses} {...props}>
            {Label}
            {expandable && <Icon icon="chevron-right" className="h-4 pr-1.5 text-gray-700" />}
          </button>
        )}

        {!expandable && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={route}
            target={isInternal ? undefined : '_blank'}
            rel={isInternal ? undefined : 'noopener noreferrer'}
            className={linkClasses}
            {...props}
            aria-current={active ? 'page' : undefined}>
            {Label}
          </a>
        )}
      </div>
    </li>
  )
}

interface LinkSecondaryProps extends MenuItemData, Omit<ComponentPropsWithoutRef<'a'>, 'name'> {
  active?: boolean
  classNameLi?: string
}

const LinkSecondary = ({
  name,
  route,
  icon,
  isInternal,
  onClick,
  active,
  classNameLi,
  children,
}: LinkSecondaryProps) => (
  <li
    key={name}
    className={clsx(
      classNameLi,
      'px-5 hover:bg-gray-50 transition-colors',
      active && 'bg-gray-50'
    )}>
    {children}
    <Link
      href={route}
      target={isInternal ? undefined : '_blank'}
      rel={isInternal ? undefined : 'noopener noreferrer'}
      color="dark"
      noUnderline
      aria-current={active ? 'page' : undefined}
      className="block py-2 text-sm !font-semibold outline-offset-2 focus:outline-2 no-underline"
      onClick={onClick}>
      {icon !== undefined && <Icon icon={icon} className="pr-1.5" />}
      {name}
    </Link>
  </li>
)

interface MainLevel2Props {
  idx: number
  level1: any
  level1Items: IMenu
  navSlideIdx: number
  setNavSlideIdx: Dispatch<SetStateAction<number>>
  activePathname: string | null
}

const MainLevel2 = ({
  idx,
  level1,
  level1Items,
  navSlideIdx,
  setNavSlideIdx,
  activePathname = '',
  ...props
}: MainLevel2Props) => {
  const { trackSnowplow } = useAnalytics()

  return (
    <Transition
      unmount={false} // Do not remove from DOM (for screen readers and performance)
      className="fixed top-0 left-0 bottom-0 bg-white h-full w-[calc(100%_-_4rem)] sm:w-96 origin-left transform z-30 overflow-y-auto overflow-x-hidden"
      show={navSlideIdx === idx}
      enter="!block transition ease-out duration-300" // !block because unmount adds display:none
      enterFrom="-translate-x-96"
      enterTo="translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-96"
      {...props}>
      <div className="w-full h-16">
        <div className="px-5">
          <button
            onClick={() => setNavSlideIdx(-1)}
            aria-label="Go back to first level"
            className="flex flex-row items-center py-2 my-3 text-gray-700 focus:outline-primary">
            <Icon icon="chevron-left" className="inline h-4 mr-4" />
            <h5 className="font-semibold">{level1?.name}</h5>
          </button>
        </div>

        <Section noDivider>
          <ul className="pb-8 list-none">
            {level1Items?.map(([level2, level2Items], idx2) => (
              <Section level2={level2} key={`${idx}.${idx2}.items`}>
                <LinkPrimary
                  expandable={false}
                  className={clsx('mt-3', level2Items.length === 0 && 'mb-3')}
                  name={level2.name}
                  route={level2.route}
                  icon={level2.icon}
                  isAppInternal={level2.isAppInternal}
                  isInternal={level2.isInternal}
                  onClick={(e: React.MouseEvent<any>) =>
                    trackSnowplow({
                      event: {
                        name: 'navigation_click',
                        data: {
                          navigation_tree: `${level1.name} > ${level2.name}`,
                          navigation_level: 2,
                          navigation_subject: level2.name,
                          navigation_url: e.currentTarget.href,
                        },
                      },
                      contexts: [{ name: 'section', data: { section_name: 'menu_drawer' } }],
                    })
                  }
                />
                {level2Items.length > 0 && (
                  <ul className="list-none mb-3" data-testid={`${idx}.${idx2}.items`}>
                    {level2Items.map(([level3], idx3) => (
                      <LinkSecondary
                        key={`${idx}.${idx2}.${idx3}`}
                        active={level3.route.toLowerCase() === activePathname}
                        {...level3}
                        onClick={(e: React.MouseEvent<any>) =>
                          trackSnowplow({
                            event: {
                              name: 'navigation_click',
                              data: {
                                navigation_tree: `${level1.name} > ${level2.name} > ${level3.name}`,
                                navigation_level: 3,
                                navigation_subject: level3.name,
                                navigation_url: e.currentTarget.href,
                              },
                            },
                            contexts: [{ name: 'section', data: { section_name: 'menu_drawer' } }],
                          })
                        }
                      />
                    ))}
                  </ul>
                )}
              </Section>
            ))}
          </ul>
        </Section>
      </div>
    </Transition>
  )
}

interface MainProps {
  navMenu: IMenu
  navSlideIdx?: number
  setNavSlideIdx: Dispatch<SetStateAction<number>>
  activePathname: string | null
}

const Main = ({ navMenu, navSlideIdx = -1, setNavSlideIdx, activePathname = '' }: MainProps) => {
  const { trackSnowplow } = useAnalytics()
  return (
    <div>
      <ul className="py-3 list-none">
        {navMenu?.map(([level1, level1Items], idx) => {
          const props = {
            icon: level1.icon,
            expandable: level1Items.length > 0,
            name: level1.name,
            route: level1.route,
            isAppInternal: level1.isAppInternal,
            isInternal: level1.isInternal,
          }
          return (
            <Fragment key={idx}>
              <LinkPrimary
                {...props}
                aria-expanded={props.expandable ? navSlideIdx === idx : undefined}
                onClick={(e: React.MouseEvent<any>) => {
                  if (props.expandable) {
                    setNavSlideIdx(idx)
                  } else {
                    trackSnowplow({
                      event: {
                        name: 'navigation_click',
                        data: {
                          navigation_tree: level1.name,
                          navigation_level: 1,
                          navigation_subject: level1.name,
                          navigation_url: e.currentTarget.href,
                        },
                      },
                      contexts: [{ name: 'section', data: { section_name: 'menu_drawer' } }],
                    })
                  }
                }}
              />
              {level1Items?.length > 0 && (
                <MainLevel2
                  key={`${idx}.items`}
                  data-testid={`${idx}.items`}
                  idx={idx}
                  level1={level1}
                  level1Items={level1Items}
                  navSlideIdx={navSlideIdx}
                  setNavSlideIdx={setNavSlideIdx}
                  activePathname={activePathname}
                />
              )}
            </Fragment>
          )
        })}
      </ul>
    </div>
  )
}

interface FeaturedProps {
  menu: IMenu
  activePathname: string | null
}

const Featured = ({ menu, activePathname }: FeaturedProps) => {
  const { trackSnowplow } = useAnalytics()
  return (
    <ul className="w-full py-3 list-none">
      {menu.map(([item], idx) => {
        return (
          <LinkSecondary
            key={idx}
            {...item}
            active={item.route.toLowerCase() === activePathname}
            onClick={(e: React.MouseEvent<any>) =>
              trackSnowplow({
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: item.name,
                    navigation_level: 1,
                    navigation_subject: item.name,
                    navigation_url: e.currentTarget.href,
                  },
                },
                contexts: [{ name: 'section', data: { section_name: 'menu_drawer' } }],
              })
            }
          />
        )
      })}
    </ul>
  )
}
export interface MenuDrawerProps extends ComponentPropsWithoutRef<'nav'> {
  menu: IMenu
  menuFeatured?: IMenu
  menuSuperheader?: IMenu
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  activePathname: string | null
  header?: JSX.Element
}

const MenuDrawer = ({
  menu,
  menuFeatured,
  children,
  isMenuOpen,
  setIsMenuOpen,
  activePathname,
  menuSuperheader,
  header,
  ...props
}: MenuDrawerProps) => {
  const [navSlideIdx, setNavSlideIdx] = useState<number>(-1)
  const containerRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const close = useCallback(() => {
    setIsMenuOpen(false)
    setNavSlideIdx(-1)
  }, [setIsMenuOpen, setNavSlideIdx])

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (isMenuOpen && event.key === 'Escape') {
        close()
      }
    },
    [isMenuOpen, close]
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const notInContainer =
        containerRef.current && !containerRef.current.contains(event.target as Node)
      const notInCloseButton =
        closeButtonRef.current && !closeButtonRef.current.contains(event.target as Node)
      if (isMenuOpen && notInContainer && notInCloseButton) {
        close()
      }
    },
    [isMenuOpen, containerRef, closeButtonRef, close]
  )

  // Close on esc key
  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  // Close on click outside menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, containerRef, closeButtonRef])

  // Disabled body scroll when open
  useEffect(() => {
    const hasVScrollbar = window.innerWidth - document.documentElement.clientWidth > 0
    const vScrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    if (isMenuOpen) {
      document.documentElement.style.overflow = 'hidden'
      if (hasVScrollbar) {
        document.documentElement.style.paddingRight = `${vScrollbarWidth}px`
      }
    } else {
      document.documentElement.style.removeProperty('overflow')
      document.documentElement.style.removeProperty('padding-right')
    }
  }, [isMenuOpen])

  return (
    <>
      <FocusTrap active={isMenuOpen} focusTrapOptions={focusTrapOptions}>
        <Transition
          unmount={false} // Do not remove from DOM (for screen readers and performance)
          className="fixed top-0 left-0 z-30 w-full h-full transition-all origin-left scale"
          show={isMenuOpen}
          enter="!block transition ease-out duration-300 delay-100"
          enterFrom="opacity-0 -translate-x-96"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-96"
          as="nav"
          aria-labelledby="menu-drawer-button"
          aria-label="Menu Drawer">
          <Button
            aria-label="Menu close"
            color="transparent"
            shape="circle"
            width="auto"
            size="sm"
            className="m-3 text-gray-700 transition-all transform !absolute top-0 left-[calc(100%_-_4rem)] sm:left-96 z-30 !outline-transparent"
            onClick={close}
            ref={closeButtonRef}>
            <Icon icon="xmark-large" className="h-5 text-white" />
          </Button>

          <div ref={containerRef} className="flex flex-col h-full w-[calc(100%_-_4rem)] sm:w-96">
            <Container>
              {!!header && (
                <div className="w-full h-16" data-testid="menu-drawer-header">
                  <div className="flex flex-row items-center justify-between">{header}</div>
                </div>
              )}

              {menuFeatured && menuFeatured.length > 0 && (
                <Section data-testid="menu-drawer-featured">
                  <Featured menu={menuFeatured} activePathname={activePathname} />
                </Section>
              )}

              <Section data-testid="menu-drawer-main">
                {!!menuSuperheader?.length && (
                  <ul className="list-none py-5">
                    {menuSuperheader?.map(([link], idx) => (
                      <LinkSecondary
                        classNameLi="pl-5 flex items-center pr-0 py-0 md:pr-5"
                        key={idx}
                        name={link.name}
                        route={link.route}
                        className="pl-0">
                        {link.icon && (
                          <Icon
                            icon={link.icon}
                            aria-hidden="false"
                            aria-label={link.name}
                            className="h-3 mr-[6px]"
                          />
                        )}
                      </LinkSecondary>
                    ))}
                  </ul>
                )}

                <Main
                  navMenu={menu}
                  navSlideIdx={navSlideIdx}
                  setNavSlideIdx={setNavSlideIdx}
                  activePathname={activePathname}
                />
              </Section>

              {children && (
                <Section data-testid="menu-drawer-footer">
                  <div className="p-5">{children}</div>
                </Section>
              )}
            </Container>
          </div>
        </Transition>
      </FocusTrap>

      <Transition
        className="fixed inset-0 z-20 block"
        as={Backdrop}
        show={isMenuOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200 delay-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      />
    </>
  )
}

MenuDrawer.Container = Container
MenuDrawer.Section = Section
MenuDrawer.Featured = Featured
MenuDrawer.Main = Main
MenuDrawer.LinkPrimary = LinkPrimary
MenuDrawer.LinkSecondary = LinkSecondary

export default MenuDrawer

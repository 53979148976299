import type { ComponentPropsWithoutRef, MouseEvent } from 'react'
import clsx from 'clsx'
import type { Menu as IMenu } from '@lib/types'
import { Container, Icon, Link } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'

export interface SuperheaderProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  menuSuperheader?: IMenu
}

const Superheader = ({ menuSuperheader, className, ...props }: SuperheaderProps) => {
  const { trackSnowplow } = useAnalytics()

  if (menuSuperheader?.length) {
    return (
      <div
        {...props}
        className={clsx('z-20 bg-white hidden lg:block', className)}
        data-testid="superheader">
        <Container className="h-12">
          <ul className="w-full h-full flex-row items-center justify-between hidden lg:flex gap-x-8">
            {menuSuperheader?.map(([link], idx) =>
              idx < 3 ? (
                <li key={idx} className={clsx(idx === 0 && 'mr-auto')}>
                  <Link
                    as="a"
                    href={link.route}
                    noUnderline
                    target={link.isInternal ? undefined : '_blank'}
                    rel={link.isInternal ? undefined : 'noopener noreferrer'}
                    // aria-current={link.route.toLowerCase() === activePathname ? 'page' : undefined}
                    className={clsx('text-sm flex items-center justify-center')}
                    onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                      trackSnowplow({
                        event: {
                          name: 'navigation_click',
                          data: {
                            navigation_tree: link.name,
                            navigation_level: 1,
                            navigation_subject: link.name,
                            navigation_url: e.currentTarget.href,
                          },
                        },
                        contexts: [{ name: 'section', data: { section_name: 'header' } }],
                      })
                    }>
                    {link.icon && (
                      <Icon
                        icon={link.icon}
                        aria-hidden="false"
                        aria-label={link.name}
                        className="mr-[6px] h-[11px]"
                      />
                    )}
                    {link.name}
                  </Link>
                </li>
              ) : null
            )}
          </ul>
        </Container>
      </div>
    )
  }
}

export default Superheader
